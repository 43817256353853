import React from "react"

const ScrollDown = () => {
  return (
    <div id="wrapper">
      <div id="wrapper-inner">
        <div id="scroll-down">
          <span className="arrow-down"></span>
          <span id="scroll-title">Scroll down</span>
        </div>
      </div>
    </div>
  )
}

export default ScrollDown
